import { render, staticRenderFns } from "./Floating.vue?vue&type=template&id=6d1487fc&scoped=true&"
import script from "./Floating.vue?vue&type=script&lang=js&"
export * from "./Floating.vue?vue&type=script&lang=js&"
import style0 from "./Floating.vue?vue&type=style&index=0&id=6d1487fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1487fc",
  null
  
)

export default component.exports