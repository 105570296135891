<template>
  <div class="float-banner">
    <div class="menu-wrap">
      <div
        class="item"
        :class="{ active: reference[0].flag }"
        @click="loadRef(0)"
      >
        <div class="speech">
          <p v-html="computedLine(reference[1].tooltip)"></p>
        </div>
        <div class="super">
          <div class="img-wrap" v-if="reference[0].flag">
            <img :src="reference[1].urlActive" />
          </div>
          <div class="img-wrap" v-else>
            <img :src="reference[1].url" />
          </div>
        </div>
      </div>
      <div
        class="item"
        v-for="(mItem, index) in menu"
        :key="index + 'B'"
        :class="{ active: mItem.flag }"
        @click="loadPr(index)"
      >
        <div class="speech">
          <p v-html="computedLine(mItem.tooltip)"></p>
        </div>
        <div class="super">
          <p>TYPE</p>
          <h3>{{ mItem.title }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reference: [
        {
          title: "참고",
          url: require("@/assets/images/ref1.svg"),
          urlActive: require("@/assets/images/ref1_active.svg"),
          flag: false,
        },

        {
          title: "소개글작성",
          url: require("@/assets/images/ref2.svg"),
          urlActive: require("@/assets/images/pen_white.svg"),
          flag: false,
          tooltip: "소개페이지 작성항목을 점검할 수 있습니다.",
        },
      ],
      menu: [
        {
          flag: false,
          title: "A",
          tooltip: "A타입 시안을 작성할 수 있습니다.",
        },
        {
          flag: false,
          title: "B",
          tooltip: "B타입 시안을 작성할 수 있습니다.",
        },
        {
          flag: false,
          title: "C",
          tooltip: "C타입 시안을 작성할 수 있습니다.",
        },
        {
          flag: false,
          title: "D",
          tooltip:
            "홈페이지에 적용할 전체 이미지를 \n등록할 수 있습니다. A4 사이즈에 \n적합한 이미지를 적용하시기 바랍니다.",
        },
        {
          flag: false,
          title: "E",
          tooltip:
            "간단한 홈페이지 링크와 함께 제작중 안내 페이지를 작성 합니다.",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    computedLine(text) {
      if (text !== null) {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      }
    },
    loadPr(order) {
      this.$emit("load-pr", order);
      /*if (order !== 3) {
        this.$emit("load-pr", order);
      } else {
        this.$emit("load-pr", 92);
      }*/
    },
    loadRef(order) {
      //console.log("플로팅오더", order);
      this.$emit("load-ref", order);
    },
    initActiveItem() {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].flag) {
          this.menu[i].flag = !this.menu[i].flag;
        }
      }
    },
    initActiveRef() {
      for (let i = 0; i < this.reference.length; i++) {
        if (this.reference[i].flag) {
          this.reference[i].flag = !this.reference[i].flag;
        }
      }
    },
    markItem(order) {
      this.initActiveItem();
      this.initActiveRef();
      //console.log("order", order);
      /*if (order === 92) {
        console.log(
          "target",
          document.querySelector(".menu-wrap").childNodes[3]
        );
        this.menu[3].flag = true;
      } else {
        console.log(
          "target",
          document.querySelector(".menu-wrap").childNodes[order]
        );
        this.menu[order].flag = true;
      }*/
      /*
      console.log(
          "target",
          document.querySelector(".menu-wrap").childNodes[order]
        );
        */
      this.menu[order].flag = true;
    },
    markRef(order) {
      this.initActiveItem();
      this.initActiveRef();
      //console.log("order", order);
      this.reference[order].flag = true;
    },
  },
  beforeUnmount() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.float-banner {
  z-index: 4;
  position: fixed;
  right: 0px;
  display: flex;
  top: 105px;
  width: 100px;
  flex-wrap: wrap;
  .reference {
    background: transparent;
    width: 100%;
    display: flex;
    height: 97px;
    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 60%;
      cursor: pointer;
      justify-content: center;
      left: 40%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-top-left-radius: 21px;
      border-bottom-left-radius: 21px;
      overflow: hidden;
      background: #fff;
      &:hover {
        background: #e7e7e7;
      }
      &:first-of-type {
        &:after {
          display: none;
        }
      }
      &.active {
        &:before {
          content: "";
          position: absolute;
          top: 12px;
          right: 12px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #146f83;
        }
      }

      &:after {
        content: "";
        height: 60px;
        width: 2px;
        background: #707070;
        position: absolute;
        top: 50%;
        left: -1px;
        transform: translateY(-50%);
      }
      .img-wrap {
        img {
        }
      }
    }
  }
  .menu-wrap {
    margin: 20px;
    .item {
      margin: 0 0 0 20px;
      padding: 10px;
      width: calc(50% - 10px);
      background: #fff;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      position: relative;
      margin: 20px 0 0 0px;
      align-self: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 71px;
      height: 71px;
      &:hover {
        .speech {
          display: flex;
        }
      }
      .speech {
        position: absolute;
        background-image: url("~@/assets/images/speech.svg");
        width: 233px;
        height: 52px;
        top: 10px;
        left: -243px;
        display: flex;
        align-items: center;
        display: none;
        transition: 0.5s;
        z-index: 0;
        p {
          padding: 0 15px;
          line-height: 15px;
          font-family: "AppleSDGothicNeoB00";
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }
      &:nth-of-type(1) {
        &.active {
        }
      }
      &:nth-of-type(2) {
        &.active {
        }
      }
      &:nth-of-type(3) {
        &.active {
        }
      }
      &:nth-of-type(4) {
        &.active {
        }
      }
      &:nth-of-type(5) {
        &.active {
        }
      }

      .super {
        .img-wrap {
          img {
            max-width: 43px;
          }
        }
      }
      &.active {
        background: #146f83;
        p,
        h3 {
          color: #fff;
        }
      }
      p {
        line-height: 15px;
        font-family: "AppleSDGothicNeoB00";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #333333;
      }
      h3 {
        line-height: 38px;
        font-family: "AppleSDGothicNeoM00";
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #333333;
      }
    }
  }
}
</style>
