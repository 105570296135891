<template>
  <v-app>
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
        hidden
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="6" style="text-align : center"
              ><img
                class="header_logo"
                src="@/assets/images/logo_default.svg"
                @click="goHome()"
                style="cursor : pointer"
            /></v-col>
            <v-col cols="12" md="6"><h1 style="font-size : 32px"></h1></v-col>
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div>
        <div class="sub-wrap" style="margin-top : 130px;">
          <side-menu
            :sideSet="sideSet"
            @call-pop-in-side-password="callPopWithSidePassword"
            @call-pop-in-side-issue="callPopWithSideIssue"
            @draw-done="initAuthInfo"
            @click-list="listReaded"
            style="margin-top : 10px"
          ></side-menu>
          <div class="write-context" v-if="view.pageOrder === null">
            <directive :directiveInfo="directiveInfo"></directive>
            <!--<v-select :options="items" v-model="selectItems"></v-select>-->
            <div class="join document">
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">병원 이름</h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  {{ memberInfo.hospNm }}
                  <!--
                <p class="warn-message" v-if="form.introduceHospital.hasError">
                  {{ form.introduceHospital.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">병원 이름 영문</h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <input
                    type="text"
                    placeholder="영문 병원이름을 입력해주세요"
                    v-model="form.hospNmEn"
                  />
                  <!--
                <p class="warn-message" v-if="form.introduceHospital.hasError">
                  {{ form.introduceHospital.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div
                class="j-article"
                v-if="index < 4 && selectItems === 'A형'"
                v-for="(attachmentItem, index) in form.attachment"
                :key="index + 'A'"
              >
                <div class="title-block">
                  <h5 class="title">{{ attachmentItem.title }}</h5>
                  <p v-if="index === 0">권장 이미지비율 (4:5)</p>
                  <p v-if="index === 1">권장 이미지비율 (1:1)</p>
                  <p v-if="index === 2">권장 이미지비율 (16:9)</p>
                  <p v-if="index === 3">권장 이미지비율 (1:1)</p>
                </div>
                <div class="attachment-wrap">
                  <h5 class="title">파일명 :</h5>
                  <div class="attachment">
                    <div class="a-input-wrap">
                      <span
                        class="file-name"
                        v-text="attachmentItem.filename"
                      ></span>
                      <input
                        class="hidden file-upload"
                        type="file"
                        @change="onFileChange(index, $event)"
                        :multiple="
                          selectItems === 'B형' || selectItems === 'C형'
                            ? true
                            : false
                        "
                        accept="image/gif, image/jpeg, image/png"
                      />
                    </div>
                    <div class="a-btn-wrap">
                      <button
                        type="button"
                        @click="attachmentFileRepeat(index)"
                      >
                        파일첨부
                      </button>
                    </div>
                    <p
                      class="warn-message"
                      v-html="computedLine(attachmentItem.warn)"
                      v-if="attachmentItem.filename === ''"
                    ></p>
                    <div
                      class="uploaded-img"
                      v-if="attachmentItem.url !== ''"
                      @click="download(index)"
                      :class="{
                        active:
                          imageMap[index].value ===
                          form.attachment[index].filename,
                      }"
                    >
                      <span
                        class="action"
                        @click="deletePicture(index)"
                        @click.stop
                        >X</span
                      >
                      <img :src="attachmentItem.url" />
                    </div>
                    <div>
                      <br />
                      <br />
                      <p v-html="computedLine(attachmentItem.text)"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="j-article"
                v-if="index < 4 && selectItems !== 'A형' && index !== 2"
                v-for="(attachmentItem, index) in form.attachment"
                :key="index + 'A'"
              >
                <div class="title-block">
                  <h5 class="title">{{ attachmentItem.title }}</h5>
                  <p v-if="index === 0">권장 이미지비율 (4:5)</p>
                  <p v-if="index === 1">권장 이미지비율 (1:1)</p>
                  <p v-if="index === 2">권장 이미지비율 (16:9)</p>
                  <p v-if="index === 3">권장 이미지비율 (1:1)</p>
                </div>
                <div class="attachment-wrap">
                  <h5 class="title">파일명 :</h5>
                  <div class="attachment">
                    <div class="a-input-wrap">
                      <span
                        class="file-name"
                        v-text="attachmentItem.filename"
                      ></span>
                      <input
                        class="hidden file-upload"
                        type="file"
                        @change="onFileChange(index, $event)"
                        :multiple="
                          selectItems === 'B형' || selectItems === 'C형'
                            ? true
                            : false
                        "
                        accept="image/gif, image/jpeg, image/png"
                      />
                    </div>
                    <div class="a-btn-wrap">
                      <button
                        type="button"
                        @click="attachmentFileRepeat(index)"
                      >
                        파일첨부
                      </button>
                    </div>
                    <p
                      class="warn-message"
                      v-html="computedLine(attachmentItem.warn)"
                      v-if="attachmentItem.filename === ''"
                    ></p>
                    <div
                      class="uploaded-img"
                      v-if="attachmentItem.url !== ''"
                      @click="download(index)"
                      :class="{
                        active:
                          imageMap[index].value ===
                          form.attachment[index].filename,
                      }"
                    >
                      <span
                        class="action"
                        @click="deletePicture(index)"
                        @click.stop
                        >X</span
                      >
                      <img :src="attachmentItem.url" />
                    </div>
                    <div>
                      <br />
                      <br />
                      <p v-html="computedLine(attachmentItem.text)"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="j-article" v-if="selectItems !== 'A형'">
                <div class="title-block">
                  <h5 class="title">병원 사진</h5>
                  <p>권장 이미지비율 (16:9)</p>
                </div>
                <div class="attachment-wrap">
                  <h5 class="title">파일명 :</h5>
                  <!-- db에서 사진 가지고올때 -->
                  <div class="attachment">
                    <div class="a-input-wrap">
                      <span
                        class="file-name"
                        v-for="(file, index) in this.multiFile"
                        v-bind:key="index + 'filename'"
                      >
                        <span v-if="file.fileNm !== undefined">{{
                          file.fileNm
                        }}</span>
                        <span v-if="file.name !== undefined">{{
                          file.name
                        }}</span>
                      </span>
                      <input
                        class="hidden file-upload1"
                        type="file"
                        @change="onFileChange(2, $event)"
                        multiple
                        accept="image/gif, image/jpeg, image/png"
                      />
                    </div>
                    <div class="a-btn-wrap">
                      <button
                        type="button"
                        @click="attachmentFileRepeatForPic()"
                      >
                        파일첨부
                      </button>
                    </div>
                    <!--X-->
                    <div
                      class="uploaded-img"
                      v-if="this.multiFile[0].length === 0"
                      @click="download(2)"
                    ></div>
                    <!--X-->
                    <!--db-->
                    <div
                      class="uploaded-img"
                      v-else-if="this.multiFile[0].fileNm !== undefined"
                      @click="download(2)"
                    >
                      <span class="action" @click="deletePicture(2)" @click.stop
                        >X</span
                      >
                      <div
                        style="height : 300px; overflow-y: auto; overflow-x: hidden;"
                      >
                        <div
                          v-for="(file, index) in this.multiFile"
                          v-bind:key="index + 'image'"
                        >
                          <img
                            :src="
                              'https://www.drrefer.net/api/picApply/' +
                                file.fileId
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <!--db-->
                    <!--local-->
                    <div
                      class="uploaded-img"
                      v-else-if="this.multiFile[0].name !== undefined"
                      @click="download(2)"
                    >
                      <span class="action" @click="deletePicture(2)" @click.stop
                        >X</span
                      >
                      <div
                        style="height : 300px; overflow-y: auto; overflow-x: hidden;"
                      >
                        <div
                          v-for="(file, index) in this.multiFile"
                          v-bind:key="index + 'image'"
                        >
                          <img :src="file.url" />
                        </div>
                      </div>
                    </div>
                    <!--local-->
                    <div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">
                    병원소개
                  </h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="병원소개 를 입력해주세요. 디자인 제작중 전체적으로 사용할 병원의 기본정보를 입력해 주세요."
                    v-model="form.introduceHospital.value"
                    @keyup="checkIntroduceHospital($event)"
                    @blur="checkIntroduceHospital($event)"
                    maxlength="100"
                  />
                  <!--
                <p class="warn-message" v-if="form.introduceHospital.hasError">
                  {{ form.introduceHospital.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">병원소개 상세</h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="병원소개 상세를 입력해주세요. 병원약력, 병원장비, 병원진단항목등"
                    v-model="form.introduceHospitalDetail.value"
                    @keyup="checkIntroduceHospitalDetail($event)"
                    @blur="checkIntroduceHospitalDetail($event)"
                    maxlength="200"
                  />
                  <!--
                <p
                  class="warn-message"
                  v-if="form.introduceHospitalDetail.hasError"
                >
                  {{ form.introduceHospitalDetail.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">
                    의사소개 {{ selectItems === "A형" ? "" : "1" }}
                  </h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="의사 소개를 입력해주세요."
                    v-model="form.introduceDoctor.value"
                    @keyup="checkIntroduceDoctor($event)"
                    @blur="checkIntroduceDoctor($event)"
                    maxlength="200"
                  />
                  <!--
                <p class="warn-message" v-if="form.introduceDoctor.hasError">
                  {{ form.introduceDoctor.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div
                class="j-article"
                v-if="selectItems === 'B형' || selectItems === 'C형'"
              >
                <div class="title-block">
                  <h5 class="title">
                    의사소개2
                  </h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="의사 소개를 입력해주세요."
                    v-model="form.introduceDoctor2.value"
                    @keyup="checkIntroduceDoctor($event)"
                    @blur="checkIntroduceDoctor($event)"
                    maxlength="200"
                  />
                </div>
              </div>
              <div
                class="j-article"
                v-if="selectItems === 'B형' || selectItems === 'C형'"
              >
                <div class="title-block">
                  <h5 class="title">
                    의사소개3
                  </h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="의사 소개를 입력해주세요."
                    v-model="form.introduceDoctor3.value"
                    @keyup="checkIntroduceDoctor($event)"
                    @blur="checkIntroduceDoctor($event)"
                    maxlength="200"
                  />
                </div>
              </div>
              <div
                class="j-article"
                v-if="selectItems === 'B형' || selectItems === 'C형'"
              >
                <div class="title-block">
                  <h5 class="title">
                    의사소개4
                  </h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="의사 소개를 입력해주세요."
                    v-model="form.introduceDoctor4.value"
                    @keyup="checkIntroduceDoctor($event)"
                    @blur="checkIntroduceDoctor($event)"
                    maxlength="200"
                  />
                </div>
              </div>
              <div
                class="j-article"
                v-if="selectItems === 'B형' || selectItems === 'C형'"
              >
                <div class="title-block">
                  <h5 class="title">
                    의사소개5
                  </h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="의사 소개를 입력해주세요."
                    v-model="form.introduceDoctor5.value"
                    @keyup="checkIntroduceDoctor($event)"
                    @blur="checkIntroduceDoctor($event)"
                    maxlength="200"
                  />
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">진료내용</h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="진료내용을 입력해주세요."
                    v-model="form.jinConts"
                  />
                  <!-- 
                <p class="warn-message" v-if="form.historyDoctor.hasError">
                  {{ form.historyDoctor.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">진료항목</h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    placeholder="진료항목을 입력해주세요. 진단항목중 전문과목, 장비도입여부, 수술 가능 여부 등"
                    v-model="form.jinItems"
                    :maxlength="selectItems === 'B형' ? '100' : '500'"
                  />
                  <!-- 
                <p class="warn-message" v-if="form.historyDoctor.hasError">
                  {{ form.historyDoctor.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">전화번호</h5>
                </div>
                <div class="input-wrap">
                  <input
                    type="text"
                    placeholder="전화번호를 입력해주세요"
                    v-model="form.tel.value"
                    @keyup="checkTel()"
                    @blur="checkTel()"
                    maxlength="13"
                  />
                  <!-- 
                <p class="warn-message" v-if="form.tel.hasError">
                  {{ form.tel.errorTxt }}
                </p>
                --></div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">팩스번호</h5>
                </div>
                <div class="input-wrap">
                  <input
                    type="text"
                    placeholder="팩스번호를 입력해주세요"
                    v-model="form.fax.value"
                    @keyup="checkFax()"
                    @blur="checkFax()"
                    maxlength="13"
                  />
                  <!-- 
                <p class="warn-message" v-if="form.fax.hasError">
                  {{ form.fax.errorTxt }}
                </p>
                --></div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">진료시간</h5>
                </div>
                <div class="input-wrap textarea-wrap">
                  <textarea
                    v-model="form.treatmentTime.value"
                    @keyup="checkTreatmentTime($event)"
                    @blur="checkTreatmentTime($event)"
                    maxlength="100"
                  />
                  <!--
                <p class="warn-message" v-if="form.treatmentTime.hasError">
                  {{ form.treatmentTime.errorTxt }}
                </p>
                 -->
                </div>
              </div>
              <div class="j-article">
                <div class="title-block">
                  <h5 class="title">홈페이지</h5>
                </div>
                <div class="input-wrap">
                  <input
                    type="text"
                    placeholder="홈페이지를 입력해주세요"
                    v-model="form.homepage.value"
                    @keyup="checkHomepage()"
                    @blur="checkHomepage()"
                    maxlength="50"
                  />
                  <div style="height : 30px; text-align : bottom;">
                    **기존에 보유하고 계신 병원 홈페이지가 존재하는 경우, 링크를
                    입력해주시기 바랍니다.
                  </div>
                </div>
              </div>
              <div
                class="j-article"
                v-for="(subPromotion, index) in form.subPromotionPageList.item"
                :key="index"
              >
                <div class="title-block">
                  <h5 class="title">기타{{ index + 1 }}</h5>
                </div>
                <div class="input-complex">
                  <!--<div class="select-place">
                  <div class="select-wrap">
                    <v-select
                      :options="form.subPromotionPageList.options"
                      :searchable="false"
                      v-model="form.subPromotionPageList.item[index].type"
                      ref="etc"
                      @input="isEtc(index)"
                    ></v-select>
                  </div>
                </div>-->
                  <div class="input-flex" style="width: calc(100% - 00px);">
                    <div class="input-wrap" @click="selectInputClick(index)">
                      <!--<input
                      type="text"
                      :disabled="
                        form.subPromotionPageList.item[index].type ===
                          '선택안함' ||
                          form.subPromotionPageList.item[index].type ===
                            '항목 선택'
                      "
                      v-model="form.subPromotionPageList.item[index].value"
                      @keyup="checkEtcPage(index)"
                      @blur="checkEtcPage(index)"
                      maxlength="50"
                      @keyup.enter="focusControl()"
                    />-->
                      <input
                        type="text"
                        placeholder="기타 더 입력하실 정보를 입력해 주세요. 디자인 제작에 도움이 됩니다."
                        v-model="form.subPromotionPageList.item[index].value"
                        @keyup="checkEtcPage(index)"
                        @blur="checkEtcPage(index)"
                        maxlength="50"
                      />
                      <!--
                    <p
                      class="warn-message"
                      v-if="form.subPromotionPageList.item[index].hasError"
                    >
                      {{ form.subPromotionPageList.item[index].errorTxt }}
                    </p>
                     -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="j-article" :class="{ last: !view.isEditorMenu }">
                <div class="title-block">
                  <h5 class="title" style="font-size: 18px !important;">
                    소개정보 추가 사용유무
                  </h5>
                </div>
                <div class="switch_wrap">
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="isEditor"
                      v-model="view.isEditorMenu"
                      @input="checkEditor()"
                    />
                    <span class="slider round"></span>
                  </label>
                  <p>에디터 사용</p>
                </div>
              </div>
              <div class="j-article" v-if="view.isEditorMenu">
                <div class="title-block">
                  <h5 class="title">추가페이지 제목</h5>
                </div>
                <div class="input-wrap">
                  <input
                    type="text"
                    placeholder="제목을 입력해주세요"
                    v-model="form.editor.title"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="j-article last" v-if="view.isEditorMenu">
                <div class="title-block">
                  <h5 class="title">추가페이지 내용</h5>
                </div>
                <div class="editor-wrap form">
                  <editor
                    :editorData="form.editor.content"
                    @sendEditContent="synchronization"
                  ></editor>
                </div>
              </div>
              <!-- order === null -->
              <div class="document-btn-wrap">
                <div class="item iconIn prev" @click="prev()">
                  <img src="@/assets/images/menuIcon/prev_mini.svg" />
                  <p>이전으로</p>
                </div>
                <div
                  class="item iconIn active"
                  @click="storeInView(view.isModifyMode)"
                >
                  <img src="@/assets/images/menuIcon/save.svg" />
                  <p v-text="view.btnName"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button id="focusControl"></button>
      <!-- <app-footer v-if="view.isFooterVisible"></app-footer> -->
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import AppHeader from "@/components/appHeader/AppHeaderTest";
import AppFooter from "@/components/AppFooter";
import Tabs from "@/components/TabsPr.vue";
import Tab from "@/components/TabPr.vue";
import Floating from "@/components/Floating.vue";
import vSelect from "vue-select";
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import Editor from "@/views/util/Editor";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  components: {
    AppHeader,
    AppFooter,
    Tab,
    Tabs,
    Floating,
    DefaultPopup,
    vSelect,
    Directive,
    Editor,
    SideMenu,
  },
  data() {
    return {
      multiFile: [
        {
          length: 0,
          fileNm: undefined,
          name: undefined,
        },
      ],
      selectItems: "A형",
      items: ["A형", "B형", "C형"],
      radioValues: "",
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      tab: [
        {
          title: "수신 진료의뢰",
        },
        {
          title: "발신 진료의뢰",
        },
      ],
      memberInfo: {
        hospNm: null,
        drNm: null,
        drId: null,
        addr: null,
        addrDtl: null,
      },
      imageMap: [
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
      directiveInfo: {
        title: "신청서 작성",
        isTitleNewLine: false,
        content:
          "디자인을 구매하기전 병원의 정보를 입력받아 디자인 대행 업체에 제공하고 있습니다.\n아래의 항목들을 작성하여 신청해주세요.\n\n*해당 항목에 작성하신 내용은 회원정보관리 - 소개정보 관리 페이지에 적용됩니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      form: {
        prodCd: "",
        aplyConts: "",
        hospNmKr: "",
        hospNmEn: "",
        jinConts: "",
        jinItems: "",
        attachment: [
          {
            isDeleted: null,
            flag: false,
            title: "의사 프로필 사진",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 로고",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 사진",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
            value: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 약도",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text:
              "**병원 약도는 입력한 주소를 기반으로 네이버 지도를 통해 우선 저장 되었습니다.\n\n개별 약도그림으로 교체를 원하시는 경우 '파일첨부'를 통해\n원하시는 이미지파일을 저장해주시길 바랍니다.",
          },
          {
            isDeleted: null,
            flag: false,
            title: "PR Image",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
        ],
        tel: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
        fax: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
        introduceHospital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원소개를 입력해주세요",
        },
        introduceHospitalDetail: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원소개 상세를 입력해주세요",
        },
        introduceDoctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor2: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor3: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor4: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        introduceDoctor5: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        historyDoctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사약력을 입력해주세요",
        },
        treatmentTime: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료시간을 입력해주세요",
        },
        homepage: {
          type: ["홈페이지"],
          value: "",
          flag: false,
        },
        sampleType: {
          options: ["A형", "B형", "C형", "D형"],
          value: "" || "항목 선택",
        },
        templateType: {
          options: ["A형", "B형", "C형", "D형", "E형"],
          value: "" || "항목 선택",
          flag: false,
          hasError: false,
          errorTxt: "*시안을 선택해주세요",
        },
        subPromotionPageList: {
          options: ["블로그", "페이스북", "트위터", "카페", "기타", "선택안함"],
          item: [
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
          ],
          isChecked: false,
        },
        resvPage: {
          type: ["예약페이지"],
          value: "",
          flag: false,
        },
        editor: {
          title: "",
          content: "",
        },
      },
      view: {
        pageOrder: null,
        isModifyMode: false,
        btnName: "",
        isEditorMenu: false,
        isFooterVisible: false,
      },
      isDetermined: false,
      setCount: false,
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
    }
    // console.log("########################################");
    // console.log(location.href);
    // console.log("########################################");
    this.getBasicData();
    this.getPromotionData();
    if (this.GET_PASSED_URL !== null) {
      this.checkPastUrl();
    }
    this.checkApplyId();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    document.querySelector(".designManageApply").classList.add("active");
  },
  updated() {
    // console.log(this.selectItems);
    // console.log(this.multiFile[0]);
  },
  computed: {
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("util", ["GET_PDF_URL", "GET_PASSED_URL"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapState("basic", ["checkPopup"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
  },
  methods: {
    ...mapActions("promotion", [
      "FETCH_PROMOTION",
      "PROMOTION_SUBMIT",
      "PROMOTION_MODIFY",
    ]),
    ...mapActions("search", ["FETCH_SEARCH"]),
    ...mapActions("join", ["FETCH_BASIC"]),
    ...mapActions("util", [
      "BRING_PDF_PR",
      "BRING_PDF_URL_PR",
      "BRING_FORM_DATA",
    ]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("util", ["SET_PASSED_URL"]),
    ...mapActions("prod", ["SAVE_APPLY", "BRING_INPROGRESS_APPLY"]),
    goHome() {
      this.$router.push("/").catch(()=>{});
    },
    async checkApplyId() {
      const inqParam = {
        drId: localStorage.getItem("drId"),
      };

      const transData = new FormData();
      const transedInqParam = JSON.stringify(inqParam);
      transData.append("inqParam", transedInqParam);
      await this.BRING_INPROGRESS_APPLY(transData).then((data) => {
        // console.log(data.param);
        // console.log(data.list);
        if (data.param !== undefined) {
          if (data.param.doctIntro2 !== "" && data.param.doctIntro2 !== null) {
            this.form.introduceDoctor2.value = data.param.doctIntro2;
            // console.log(this.form.introduceDoctor2.value);
          }
          if (data.param.doctIntro3 !== "" && data.param.doctIntro3 !== null) {
            this.form.introduceDoctor3.value = data.param.doctIntro3;
          }
          if (data.param.doctIntro4 !== "" && data.param.doctIntro4 !== null) {
            this.form.introduceDoctor4.value = data.param.doctIntro4;
          }
          if (data.param.doctIntro5 !== "" && data.param.doctIntro5 !== null) {
            this.form.introduceDoctor5.value = data.param.doctIntro5;
          }
          if (data.param.jinConts !== "" && data.param.jinConts !== null) {
            this.form.jinConts = data.param.jinConts;
          }
          if (data.param.jinItems !== "" && data.param.jinItems !== null) {
            this.form.jinItems = data.param.jinItems;
          }
          if (data.param.jinItems !== "" && data.param.jinItems !== null) {
            this.form.hospNmEn = data.param.hospEngNm;
          }
          if (data.param.prodCd !== "" && data.param.prodCd !== null) {
            this.form.prodCd = data.param.prodCd;
          }
          ////////////////////////////////////////////////////////////////////////////
          if (this.form.prodCd === "") {
            // console.log(localStorage.getItem("prodCd"));
            if (localStorage.getItem("prodCd") === "10010") {
              this.selectItems = "A형";
            } else if (localStorage.getItem("prodCd") === "10020") {
              this.selectItems = "B형";
            } else if (localStorage.getItem("prodCd") === "10030") {
              this.selectItems = "C형";
            }
          } else {
            localStorage.setItem("prodCd", this.form.prodCd);
            if (this.form.prodCd === "10010") {
              this.selectItems = "A형";
            } else if (this.form.prodCd === "10020") {
              this.selectItems = "B형";
            } else if (this.form.prodCd === "10030") {
              this.selectItems = "C형";
            }
          }
          ///////////////////////////////////////////////////////////////////////////
          if (data.list !== "" && data.list !== null) {
            this.multiFile = data.list;
            if (this.multiFile.length === 0) {
              this.multiFile = [
                {
                  length: 0,
                  fileNm: undefined,
                  name: undefined,
                },
              ];
            }
            // console.log(this.multiFile);
          }
        } else {
          if (this.form.prodCd === "") {
            // console.log(localStorage.getItem("prodCd"));
            if (localStorage.getItem("prodCd") === "10010") {
              this.selectItems = "A형";
            } else if (localStorage.getItem("prodCd") === "10020") {
              this.selectItems = "B형";
            } else if (localStorage.getItem("prodCd") === "10030") {
              this.selectItems = "C형";
            }
          } else {
            localStorage.setItem("prodCd", this.form.prodCd);
            if (this.form.prodCd === "10010") {
              this.selectItems = "A형";
            } else if (this.form.prodCd === "10020") {
              this.selectItems = "B형";
            } else if (this.form.prodCd === "10030") {
              this.selectItems = "C형";
            }
          }
        }
      });
    },
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      // console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSideIssue(subData) {
      // console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    phoneFomatter(number) {
      // console.log("정보", number);
      // console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        // console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    synchronization(editorData) {
      this.form.editor.content = editorData;
    },
    initEditorData() {
      this.form.editor.title = null;
      this.form.editor.content = null;
    },
    checkEditor() {
      // console.log(this.view.isEditorMenu);
      if (this.view.isEditorMenu === true) {
        this.initEditorData();
      }
    },
    async checkPrData() {
      try {
        await this.FETCH_SEARCH().then(() => {
          if (localStorage.getItem("popYn") !== null) {
            localStorage.setItem("popYn", "Y");
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    checkPastUrl() {
      if (this.GET_PASSED_URL === "manage") {
        this.isDetermined = true;
      }
    },
    download(order) {
      if (order === 0) {
        if (
          this.GET_PROMOTION.drPicAttchNm === this.form.attachment[0].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" +
              this.GET_PROMOTION.drPicAttchId,
            "_blank"
          );
        }
      } else if (order === 1) {
        if (
          this.GET_PROMOTION.logoAttchNm === this.form.attachment[1].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.logoAttchId,
            "_blank"
          );
        }
      } else if (order === 2) {
        if (
          this.GET_PROMOTION.picAttchNm === this.form.attachment[2].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.picAttchId,
            "_blank"
          );
        }
      } else if (order === 3) {
        if (
          this.GET_PROMOTION.mapAttchNm === this.form.attachment[3].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.mapAttchId,
            "_blank"
          );
        }
      }
    },
    async getBasicData() {
      try {
        await this.FETCH_BASIC().then(() => {
          // console.log("찾기 회원정보", this.GET_BASIC);
          if (this.GET_BASIC.hospNm !== null) {
            this.memberInfo.hospNm = this.GET_BASIC.hospNm;
          }
          if (this.GET_BASIC.drNm !== null) {
            this.memberInfo.drNm = this.GET_BASIC.drNm;
          }
          if (this.GET_BASIC.drId !== null) {
            this.memberInfo.drId = this.GET_BASIC.drId;
          }
          if (this.GET_BASIC.addr !== null) {
            this.memberInfo.addr = this.GET_BASIC.addr;
          }
          if (this.GET_BASIC.addrDtl !== null) {
            this.memberInfo.addrDtl = this.GET_BASIC.addrDtl;
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    checkTreatmentTime(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.treatmentTime.value.match(/\n/g);
      const separatedLines = this.form.treatmentTime.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          if (lines.length === 1) {
            /** 길이가 1이상이면 */
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
            "\n" + separatedLines[3];
          }
          // console.log("현재 어떻게!!?", lines.length);
          // this.form.treatmentTime.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            // console.log("1보다 큰 진입");
            /** 길이가 1이상이면 */
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else if (lines.length === 3) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          } else if (lines.length === 4) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4];
          } else if (lines.length === 5) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5];
          } else if (lines.length === 6) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6];
          } else if (lines.length === 7) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7] +
              "\n" +
              separatedLines[8];
          }
          // console.log("현재 어떻게?", lines.length);
          this.form.treatmentTime.value = restrictStr;
        }
      }
      if (this.form.treatmentTime.value !== null) {
        this.form.treatmentTime.flag = false;
        this.form.treatmentTime.hasError = true;
        const TreatmentTime = this.form.treatmentTime.value.replace(/\s/gi, "");

        if (TreatmentTime === "") {
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          this.form.treatmentTime.errorTxt = "*진료시간을 입력해주세요.";
          return false;
        }
        if (TreatmentTime.length < 5) {
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          this.form.treatmentTime.errorTxt =
            "*진료시간을 5자 이상 입력해주세요.";
          return false;
        }
        if (TreatmentTime.length > 100) {
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          this.form.treatmentTime.errorTxt =
            "*진료시간을 60자 이하로 입력해주세요.";
          const temper = this.form.treatmentTime.value.substring(0, 100);
          this.form.treatmentTime.value = temper;
          event.target.value.substring(0, 100);
          return false;
        }
        const isTreatmentTime = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,100}$/;
        if (!isTreatmentTime.test(TreatmentTime)) {
          this.form.treatmentTime.errorTxt =
            "*특수문자를 제외하고 100자 내로 입력이 가능합니다.";
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          return false;
        }
        // console.log("성공렝", TreatmentTime.length);
        this.form.treatmentTime.flag = true;
        this.form.treatmentTime.hasError = false;
        this.form.treatmentTime.errorTxt = "";
        return true;
      }
    },
    checkEtcPage(order) {
      if (this.form.subPromotionPageList.item[order].value !== null) {
        this.form.subPromotionPageList.item[order].flag = false;
        this.form.subPromotionPageList.item[order].hasError = true;
        const etcUrl = this.form.subPromotionPageList.item[order].value.replace(
          /\s/gi,
          ""
        );
        if (etcUrl === "") {
          this.form.subPromotionPageList.item[order].flag = false;
          this.form.subPromotionPageList.item[order].hasError = true;
          this.form.subPromotionPageList.item[order].errorTxt =
            "*홈페이지 주소를 입력해주세요.";
          return false;
        }
        if (etcUrl.length < 5) {
          this.form.subPromotionPageList.item[order].flag = false;
          this.form.subPromotionPageList.item[order].hasError = true;
          this.form.subPromotionPageList.item[order].errorTxt =
            "*홈페이지 주소를 5자이상 입력해주세요.";
          return false;
        }
        const isEtcUrl = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|-|]{5,200}$/;
        if (!isEtcUrl.test(etcUrl)) {
          this.form.subPromotionPageList.item[order].errorTxt =
            "*특수문자를 제외하고 50자 내로 입력이 가능합니다.";
          this.form.subPromotionPageList.item[order].flag = false;
          this.form.subPromotionPageList.item[order].hasError = true;
          return false;
        }
        this.form.subPromotionPageList.item[order].flag = true;
        this.form.subPromotionPageList.item[order].hasError = false;
        return true;
      }
    },
    selectInputClick(order) {
      if (this.form.subPromotionPageList.item[order].type === "항목 선택") {
        this.$refs.etc[order].$el.parentElement.classList.add("caution");
        this.form.subPromotionPageList.item[order].hasError = true;
      }
    },
    isEtc(order) {
      this.$refs.etc[order].$el.parentElement.classList.remove("caution");
      if (this.form.subPromotionPageList.item[order].type !== "선택안함") {
        this.form.subPromotionPageList.item[order].hasError = true;
      } else {
        this.form.subPromotionPageList.item[order].hasError = false;
      }
      this.form.subPromotionPageList.item[order].flag = false;
      this.form.subPromotionPageList.item[order].value = "";
    },
    checkHomepage() {
      if (this.form.homepage.value !== null) {
        this.form.homepage.flag = false;
        const Homepage = this.form.homepage.value.replace(/\s/gi, "");
        if (Homepage === "") {
          this.form.homepage.flag = false;
          return false;
        }
        this.form.homepage.flag = true;
        return true;
      }
    },
    checkFax() {
      if (this.form.fax.value !== null) {
        this.form.fax.flag = false;
        this.form.fax.hasError = true;
        const fax = this.form.fax.value.replace(/\s/gi, "");
        if (fax === "") {
          this.form.fax.flag = false;
          this.form.fax.hasError = true;
          this.form.fax.errorTxt = "*팩스번호를 입력해주세요.";
          return false;
        }
        const isFax = /^[0-9|-]{10,13}$/;
        if (!isFax.test(fax)) {
          this.form.fax.errorTxt = "*-와 숫자만 입력 가능합니다.";
          this.form.fax.flag = false;
          this.form.fax.hasError = true;
          return false;
        }
        this.form.fax.flag = true;
        this.form.fax.hasError = false;
        this.form.fax.errorTxt = "";
        return true;
      }
    },
    checkTel() {
      if (this.form.tel.value !== null) {
        this.form.tel.flag = false;
        this.form.tel.hasError = true;
        const tel = this.form.tel.value.replace(/\s/gi, "");
        if (tel === "") {
          this.form.tel.flag = false;
          this.form.tel.hasError = true;
          this.form.tel.errorTxt = "*전화번호를 입력해주세요.";
          return false;
        }
        const isTel = /^[0-9|-]{10,13}$/;
        if (!isTel.test(tel)) {
          this.form.tel.errorTxt = "*-와 숫자만 입력 가능합니다.";
          this.form.tel.flag = false;
          this.form.tel.hasError = true;
          return false;
        }
        this.form.tel.flag = true;
        this.form.tel.hasError = false;
        this.form.tel.errorTxt = "";
        return true;
      }
    },
    checkIntroduceDoctor(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.introduceDoctor.value.match(/\n/g);
      const separatedLines = this.form.introduceDoctor.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          // console.log("현재의 라인", lines.length);
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else if (lines.length === 3) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          } else if (lines.length === 4) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4];
          } else if (lines.length === 5) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5];
          } else if (lines.length === 6) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6];
          } else if (lines.length === 7) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7] +
              "\n" +
              separatedLines[8];
          }
          this.form.introduceDoctor.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          // console.log("현재의 라인", lines.length);
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else if (lines.length === 3) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          } else if (lines.length === 4) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4];
          } else if (lines.length === 5) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6];
          }
          this.form.introduceDoctor.value = restrictStr;
        }
      }
      if (this.form.introduceDoctor.value !== null) {
        this.form.introduceDoctor.flag = false;
        this.form.introduceDoctor.hasError = true;
        const IntroduceDoctor = this.form.introduceDoctor.value.replace(
          /\s/gi,
          ""
        );

        if (IntroduceDoctor === "") {
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          this.form.introduceDoctor.errorTxt = "*의사소개를 입력해주세요.";
          return false;
        }
        if (IntroduceDoctor.length < 5) {
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          this.form.introduceDoctor.errorTxt =
            "*의사소개를 5자 이상 입력해주세요.";
          return false;
        }
        if (IntroduceDoctor.length > 200) {
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          this.form.introduceDoctor.errorTxt =
            "*의사소개를 50자 이하로 입력해주세요.";
          const temper = this.form.introduceDoctor.value.substring(0, 200);
          this.form.introduceDoctor.value = temper;
          event.target.value.substring(0, 200);
          return false;
        }
        const isIntroduceDoctor = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,200}$/;
        if (!isIntroduceDoctor.test(IntroduceDoctor)) {
          this.form.introduceDoctor.errorTxt =
            "*특수문자를 제외하고 200자 내로 입력이 가능합니다.";
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          return false;
        }
        // console.log("현재길이는?", IntroduceDoctor.length);
        this.form.introduceDoctor.flag = true;
        this.form.introduceDoctor.hasError = false;
        this.form.introduceDoctor.errorTxt = "";
        return true;
      }
    },
    checkHistoryDoctor(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.historyDoctor.value.match(/\n/g);
      const separatedLines = this.form.historyDoctor.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          }
          this.form.historyDoctor.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          }
          this.form.historyDoctor.value = restrictStr;
        }
      }

      if (this.form.historyDoctor.value !== null) {
        this.form.historyDoctor.flag = false;
        this.form.historyDoctor.hasError = true;
        const HistoryDoctor = this.form.historyDoctor.value.replace(/\s/gi, "");

        if (HistoryDoctor === "") {
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          this.form.historyDoctor.errorTxt = "*의사약력을 입력해주세요.";
          return false;
        }
        if (HistoryDoctor.length < 5) {
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          this.form.historyDoctor.errorTxt =
            "*의사약력을 5자 이상 입력해주세요.";
          return false;
        }
        if (HistoryDoctor.length > 87) {
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          this.form.historyDoctor.errorTxt =
            "*의사약력을 87자 이하로 입력해주세요.";
          const temper = this.form.historyDoctor.value.substring(0, 87);
          this.form.historyDoctor.value = temper;
          event.target.value.substring(0, 87);
          return false;
        }
        const isHistoryDoctor = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,87}$/;
        if (!isHistoryDoctor.test(HistoryDoctor)) {
          this.form.historyDoctor.errorTxt =
            "*특수문자를 제외하고 87자 내로 입력이 가능합니다.";
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          return false;
        }
        // console.log("현재길이는", HistoryDoctor.length);
        this.form.historyDoctor.flag = true;
        this.form.historyDoctor.hasError = false;
        this.form.historyDoctor.errorTxt = "";
        return true;
      }
    },
    checkIntroduceHospitalDetail(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.introduceHospitalDetail.value.match(/\n/g);
      const separatedLines = this.form.introduceHospitalDetail.value.split(
        "\n"
      );
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          }
          this.form.introduceHospitalDetail.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          }
          this.form.introduceHospitalDetail.value = restrictStr;
        }
      }

      if (this.form.introduceHospitalDetail.value !== null) {
        this.form.introduceHospitalDetail.flag = false;
        this.form.introduceHospitalDetail.hasError = true;
        const IntroduceHospitalDetail = this.form.introduceHospitalDetail.value.replace(
          /\s/gi,
          ""
        );

        if (IntroduceHospitalDetail === "") {
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          this.form.introduceHospitalDetail.errorTxt =
            "*병원 상세내용을 입력해주세요.";
          return false;
        }
        if (IntroduceHospitalDetail.length < 5) {
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          this.form.introduceHospitalDetail.errorTxt =
            "*병원 상세내용을 5자 이상 입력해주세요.";
          return false;
        }
        if (IntroduceHospitalDetail.length > 200) {
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          this.form.introduceHospitalDetail.errorTxt =
            "*병원 상세내용을 200자 이하로 입력해주세요.";
          const temper = this.form.introduceHospitalDetail.value.substring(
            0,
            200
          );
          this.form.introduceHospitalDetail.value = temper;
          event.target.value.substring(0, 200);
          return false;
        }
        const isIntroduceHospitalDetail = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,200}$/;
        if (!isIntroduceHospitalDetail.test(IntroduceHospitalDetail)) {
          this.form.introduceHospitalDetail.errorTxt =
            "*특수문자를 제외하고 200자 내로 입력이 가능합니다.";
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          return false;
        }
        // console.log("현재의 길이는", IntroduceHospitalDetail.length);
        this.form.introduceHospitalDetail.flag = true;
        this.form.introduceHospitalDetail.hasError = false;
        this.form.introduceHospitalDetail.errorTxt = "";
        return true;
      }
    },
    checkIntroduceHospital(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.introduceHospital.value.match(/\n/g);
      const separatedLines = this.form.introduceHospital.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          this.form.introduceHospital.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            /** 길이가 1이상이면 */
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          }
          this.form.introduceHospital.value = restrictStr;
        }
      }

      if (this.form.introduceHospital.value !== null) {
        this.form.introduceHospital.flag = false;
        this.form.introduceHospital.hasError = true;
        const IntroduceHospital = this.form.introduceHospital.value.replace(
          /\s/gi,
          ""
        );

        if (IntroduceHospital === "") {
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          this.form.introduceHospital.errorTxt = "*병원소개를 입력해주세요.";
          return false;
        }
        if (IntroduceHospital.length < 5) {
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          this.form.introduceHospital.errorTxt =
            "*병원소개를 5자 이상 입력해주세요.";
          return false;
        }
        if (IntroduceHospital.length > 100) {
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          this.form.introduceHospital.errorTxt =
            "*병원소개를 100자 이하로 입력해주세요.";
          const temper = this.form.introduceHospital.value.substring(0, 100);
          // console.log("temper", temper);
          this.form.introduceHospital.value = temper;
          event.target.value.substring(0, 10);
          return false;
        }
        const isIntroduceHospital = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,100}$/;
        if (!isIntroduceHospital.test(IntroduceHospital)) {
          this.form.introduceHospital.errorTxt =
            "*특수문자를 제외하고 100자 내로 입력이 가능합니다.";
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          return false;
        }
        // console.log("현재의 길이는", IntroduceHospital.length);
        this.form.introduceHospital.flag = true;
        this.form.introduceHospital.hasError = false;
        this.form.introduceHospital.errorTxt = "";
        return true;
      }
    },
    prePareTextareaTypeB() {
      const wholeTextarea = document.querySelectorAll(".textarea-wrap");
      let target;
      let str;
      let transedStr;
      let mutatedStr;
      for (let i = 0; i < wholeTextarea.length; i++) {
        if (i === 0) {
          str = this.form.historyDoctor.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 1) {
          str = this.form.introduceDoctor.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 2) {
          str = this.form.introduceHospital.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 3) {
          str = this.form.introduceHospitalDetail.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 4) {
          str = this.form.treatmentTime.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        }
      }
    },
    prePareTextarea() {
      const wholeTextarea = document.querySelectorAll(".textarea-wrap");
      let target;
      let str;
      let transedStr;
      let mutatedStr;
      for (let i = 0; i < wholeTextarea.length; i++) {
        if (i === 0) {
          str = this.form.introduceHospital.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 1) {
          str = this.form.introduceHospitalDetail.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 2) {
          str = this.form.historyDoctor.value;

          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 3) {
          str = this.form.introduceDoctor.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 4) {
          str = this.form.treatmentTime.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        }
      }
      /** str.replace(/(?:\r\n|\r|\n)/g, "<br/>"); */
      /** str.replaceAll("<br/>", "\r\n"); */
    },
    checkPrHospIntro() {
      const IntroduceHospital = this.form.introduceHospital.value.replace(
        /\s/gi,
        ""
      );
      // console.log("hos체크", IntroduceHospital.length);
      if (IntroduceHospital.length > 90) {
        this.form.introduceHospital.flag = false;
        this.form.introduceHospital.hasError = true;
        this.form.introduceHospital.errorTxt = "글자수가 초과되었습니다.";
      }
      this.form.introduceHospital.flag = true;
      this.form.introduceHospital.hasError = false;
      return true;
    },
    prohibition(type) {
      if (type === "hospNm") {
        this.popupSet.title = "병원명 변경불가";
        this.popupSet.content =
          "'병원명' 은 기본정보 수정에서 변경할 수 있습니다.";
      } else if (type === "drNm") {
        this.popupSet.title = "병원명, 의사이름 변경불가";
        this.popupSet.content =
          "'병원명 및 의사이름' 은 기본정보 수정에서 변경할 수 있습니다.";
      } else if (type == "address") {
        this.popupSet.title = "주소 변경불가";
        this.popupSet.content =
          "'주소' 는 기본정보 수정에서 변경할 수 있습니다.";
      }
      this.SET_POPUP(true);

      this.popupSet.popType = "warn";
      this.popupSet.cancelBtnText = "확인";
    },
    prev() {
      window.history.go(-3);
    },

    deletePicture(order, message) {
      // console.log(order);
      this.multiFile = [
        {
          length: 0,
          fileNm: undefined,
          name: undefined,
        },
      ];
      this.form.attachment[order].isDeleted = 1;
      this.form.attachment[order].flag = false;
      this.form.attachment[order].source = null;
      this.form.attachment[order].url = "";
      this.form.attachment[order].filename = "";
      /*if (message === undefined) {
        document
          .querySelector(".join")
          .childNodes[order].querySelector("input.file-upload").value = "";
      }*/
    },
    async getPromotionData() {
      /**
       *  삭제,변경 => 1
       *  유지,입력 => 0
       *
       */
      try {
        await this.FETCH_PROMOTION().then(() => {
          // console.log("찾기", this.GET_PROMOTION);
          // console.log(this.form.sampleType.value);
          // console.log(this.GET_PROMOTION.hospIntroType);
          if (
            this.GET_PROMOTION.hospIntroType !== null &&
            this.GET_PROMOTION.hospIntroType !== ""
          ) {
            if (this.GET_PROMOTION.hospIntroType === "0") {
              this.form.sampleType.value = "C형";
            } else if (this.GET_PROMOTION.hospIntroType === "1") {
              this.form.sampleType.value = "D형";
            } else if (this.GET_PROMOTION.hospIntroType === "2") {
              this.form.sampleType.value = "E형";
            } else if (this.GET_PROMOTION.hospIntroType === "3") {
              this.form.sampleType.value = "B형";
            } else if (this.GET_PROMOTION.hospIntroType === "4") {
              this.form.sampleType.value = "A형";
            }
          }
          this.form.tel.value =
            this.GET_PROMOTION.hospTel === null
              ? ""
              : this.phoneFomatter(this.GET_PROMOTION.hospTel);
          // console.log(this.form.tel.value);
          this.form.tel.flag =
            this.GET_PROMOTION.hospTel !== null ? true : false;
          if (this.GET_PROMOTION.doctIntro !== null) {
            this.view.isModifyMode = true;
            this.view.btnName = "신청서 작성";
            //this.loadRef(0);
            if (
              this.GET_PROMOTION.drPicAttchId !== null &&
              this.GET_PROMOTION.drPicAttchId !== ""
            ) {
              this.form.attachment[0].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
              this.form.attachment[0].filename = this.GET_PROMOTION.drPicAttchNm;
              this.imageMap[0].value = this.GET_PROMOTION.drPicAttchNm;
            }
            if (
              this.GET_PROMOTION.logoAttchId !== null &&
              this.GET_PROMOTION.logoAttchId !== ""
            ) {
              this.form.attachment[1].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
              this.form.attachment[1].filename = this.GET_PROMOTION.logoAttchNm;
              this.imageMap[1].value = this.GET_PROMOTION.logoAttchNm;
            }
            if (
              this.GET_PROMOTION.picAttchId !== null &&
              this.GET_PROMOTION.picAttchId !== ""
            ) {
              this.form.attachment[2].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
              this.form.attachment[2].filename = this.GET_PROMOTION.picAttchNm;
              this.imageMap[2].value = this.GET_PROMOTION.picAttchNm;
            }
            if (
              this.GET_PROMOTION.mapAttchId !== null &&
              this.GET_PROMOTION.mapAttchId !== ""
            ) {
              this.form.attachment[3].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
              this.form.attachment[3].filename = this.GET_PROMOTION.mapAttchNm;
              this.imageMap[3].value = this.GET_PROMOTION.mapAttchNm;
            }
            if (
              this.GET_PROMOTION.prAttchId !== null &&
              this.GET_PROMOTION.prAttchId !== ""
            ) {
              // console.log("qweqweqew0");
              // console.log(this.GET_PROMOTION);
              // console.log(this.form.attachment[4]);
              this.form.attachment[4].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
              this.form.attachment[4].filename = this.GET_PROMOTION.prAttchNm;
            }
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.form.editor.title = this.GET_PROMOTION.docAddvTitle;
              this.form.editor.content = this.GET_PROMOTION.docAddvConts;
              this.$nextTick((_) => {
                this.view.isEditorMenu = true;
              });
            }
            this.form.attachment[0].isDeleted = 0;
            this.form.attachment[1].isDeleted = 0;
            this.form.attachment[2].isDeleted = 0;
            this.form.attachment[3].isDeleted = 0;
            this.form.attachment[4].isDeleted = 0;

            this.form.fax.value =
              this.GET_PROMOTION.hospFaxNo === null
                ? ""
                : this.phoneFomatter(this.GET_PROMOTION.hospFaxNo);
            this.form.fax.flag =
              this.GET_PROMOTION.hospFaxNo !== null ? true : false;
            this.form.introduceHospital.value =
              this.GET_PROMOTION.hospIntro === null
                ? ""
                : this.GET_PROMOTION.hospIntro;
            this.form.introduceHospital.flag =
              this.GET_PROMOTION.hospIntro !== null ? true : false;
            this.form.introduceHospitalDetail.value =
              this.GET_PROMOTION.conConts === null
                ? ""
                : this.GET_PROMOTION.conConts;
            this.form.introduceHospitalDetail.flag =
              this.GET_PROMOTION.conConts !== null ? true : false;
            this.form.introduceDoctor.value =
              this.GET_PROMOTION.doctIntro === null
                ? ""
                : this.GET_PROMOTION.doctIntro;
            this.form.introduceDoctor.flag =
              this.GET_PROMOTION.doctIntro !== null ? true : false;
            this.form.historyDoctor.value =
              this.GET_PROMOTION.drHist === null
                ? ""
                : this.GET_PROMOTION.drHist;
            this.form.historyDoctor.flag =
              this.GET_PROMOTION.drHist !== null ? true : false;
            this.form.treatmentTime.value =
              this.GET_PROMOTION.etcConts === null
                ? ""
                : this.GET_PROMOTION.etcConts;
            this.form.treatmentTime.flag =
              this.GET_PROMOTION.etcConts !== null ? true : false;
            this.form.homepage.value =
              this.GET_PROMOTION.homepage === null
                ? ""
                : this.GET_PROMOTION.homepage;
            this.form.homepage.flag =
              this.GET_PROMOTION.homepage !== null ? true : false;
            this.form.subPromotionPageList.item[0].type =
              this.GET_PROMOTION.urlNm1 === null
                ? ""
                : this.GET_PROMOTION.urlNm1;
            this.form.subPromotionPageList.item[0].value =
              this.GET_PROMOTION.url1 === null ? "" : this.GET_PROMOTION.url1;
            this.form.subPromotionPageList.item[0].flag =
              this.GET_PROMOTION.url1 !== null ? true : false;
            this.form.subPromotionPageList.item[1].type =
              this.GET_PROMOTION.urlNm2 === null
                ? ""
                : this.GET_PROMOTION.urlNm2;
            this.form.subPromotionPageList.item[1].value =
              this.GET_PROMOTION.url2 === null ? "" : this.GET_PROMOTION.url2;
            this.form.subPromotionPageList.item[1].flag =
              this.GET_PROMOTION.url2 !== null ? true : false;
            this.form.subPromotionPageList.item[2].type =
              this.GET_PROMOTION.urlNm3 === null
                ? ""
                : this.GET_PROMOTION.urlNm3;
            this.form.subPromotionPageList.item[2].value =
              this.GET_PROMOTION.url3 === null ? "" : this.GET_PROMOTION.url3;
            this.form.subPromotionPageList.item[2].flag =
              this.GET_PROMOTION.url3 !== null ? true : false;
            this.form.resvPage.value =
              this.GET_PROMOTION.rsvUrl === null
                ? ""
                : this.GET_PROMOTION.rsvUrl;
            this.form.resvPage.flag =
              this.GET_PROMOTION.rsvUrl !== null ? true : false;
          } else {
            this.view.isModifyMode = false;
            this.view.btnName = "신청서 작성";
            this.view.pageOrder = null;
            //this.loadRef(0);
            if (
              this.GET_PROMOTION.mapAttchId !== null &&
              this.GET_PROMOTION.mapAttchId !== ""
            ) {
              this.form.attachment[3].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
              this.form.attachment[3].isDeleted = 0;
              this.form.attachment[3].filename = this.GET_PROMOTION.mapAttchNm;
              this.imageMap[3].value = this.GET_PROMOTION.mapAttchNm;
            }
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    checkReservationPage() {
      if (this.form.resvPage.value !== null) {
        this.form.resvPage.flag = false;
        const ResvPage = this.form.resvPage.value.replace(/\s/gi, "");
        if (ResvPage === "") {
          this.form.resvPage.flag = false;
          return false;
        }
        this.form.resvPage.flag = true;
        return true;
      }
    },
    onFileChange(order, event) {
      // console.log("in");
      this.multiFile = [
        {
          length: 0,
          fileNm: undefined,
          name: undefined,
        },
      ];
      this.form.attachment[order].url = "";
      this.form.attachment[order].filename = "";
      this.form.attachment[order].flag = true;
      this.form.attachment[order].isDeleted = 0;
      const getFile = event.target.files;
      if (
        order === 2 &&
        (this.selectItems === "B형" || this.selectItems === "C형")
      ) {
        if (this.selectItems === "B형" && event.target.files.length >= 3) {
          alert(
            "B형 신청서 에서는 병원사진을 최대 3장까지 사용하실 수 있습니다."
          );
        } else {
          var fileArray = {};

          for (var i = 0; i < event.target.files.length; i++) {
            // console.log("가져올 파일명: ", event.target.files[i].name);
            // console.log(
            //   "가져올 파일의 URL",
            //   URL.createObjectURL(event.target.files[i])
            // );
            fileArray[i] = event.target.files[i];
            fileArray[i].url = URL.createObjectURL(event.target.files[i]);
            //console.log(fileArray);
          }

          this.multiFile = event.target.files;
          // console.log(order);
          // console.log(this.multiFile);
        }
      } else {
        const getFileUrl = URL.createObjectURL(event.target.files[0]);
        // console.log("가져올 파일명: ", getFile[0].name);
        // console.log("가져올 파일의 URL", getFileUrl);
        this.form.attachment[order].source = event.target.files[0]; ///////////check sein 03-25
        /** getFile */
        this.form.attachment[order].url = getFileUrl;
        this.form.attachment[order].filename = getFile[0].name;
        this.form.attachment[order].flag = true;
        this.form.attachment[order].isDeleted = 0;
        // console.log("order", this.form.attachment[order].flag);
        // console.log("파일", event.target.files[0]);
        // console.log("소스", this.form.attachment[order].source);
      }
    },
    attachmentFileRepeat(order, type) {
      // console.log("=--------====================");
      // console.log("order Repeat: ", order);
      let parentElement;
      if (type !== undefined) {
        parentElement = document.querySelector(".wholeImg");
      } else {
        if (this.selectItems === "A형") {
          parentElement = document.querySelector(".document").childNodes[
            order + 2
          ];
        } else {
          parentElement = document.querySelector(".document").childNodes[
            order + 7
          ];
        }
      }
      parentElement.querySelector(".file-upload").click();
    },
    attachmentFileRepeatForPic(type) {
      let parentElement;
      if (type !== undefined) {
        parentElement = document.querySelector(".wholeImg");
      } else {
        parentElement = document.querySelector(".document");
      }

      parentElement.querySelector(".file-upload1").click();
    },
    attachmentFile(order) {
      if (order === 0) {
        document.querySelector(".profile .file-upload").click();
      } else if (order === 1) {
        document.querySelector(".hospital-logo .file-upload").click();
      } else if (order === 2) {
        document.querySelector(".hsPicrue .file-upload").click();
      } else if (order === 3) {
        document.querySelector(".hsMap .file-upload").click();
      }
    },
    checkFlag() {
      if (
        !this.form.tel.flag ||
        !this.form.introduceHospital.flag ||
        !this.form.introduceDoctor.flag ||
        !this.form.treatmentTime.flag ||
        !this.form.templateType.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.tel.flag) {
          this.form.tel.hasError = true;
        }
        if (!this.form.introduceHospital.flag) {
          this.form.introduceHospital.hasError = true;
        }
        if (!this.form.introduceDoctor.flag) {
          this.form.introduceDoctor.hasError = true;
        }
        if (!this.form.treatmentTime.flag) {
          this.form.treatmentTime.hasError = true;
        }
        if (!this.form.templateType.flag) {
          this.form.templateType.hasError = true;
        }
        return false;
      }
      return true;
    },
    async storeInView(type) {
      try {
        const prParam = {
          drId: localStorage.getItem("drId"),
          isDeletedDrPicAttchId: this.form.attachment[0].isDeleted,
          isDeletedLogoAttchId: this.form.attachment[1].isDeleted,
          isDeletedPicAttchId: this.form.attachment[2].isDeleted,
          isDeletedMapAttchId: this.form.attachment[3].isDeleted,
          isDeletedPrAttchId: this.form.attachment[4].isDeleted,
          hospTel: this.form.tel.value.replace(/-/gi, ""),
          hospFaxNo: this.form.fax.value.replace(/-/gi, ""),
          hospIntro: this.form.introduceHospital.value,
          conConts: this.form.introduceHospitalDetail.value,
          doctIntro: this.form.introduceDoctor.value,
          drHist: this.form.historyDoctor.value,
          etcConts: this.form.treatmentTime.value,
          homepage:
            this.form.homepage.value === "" || this.form.homepage.value === null
              ? null
              : this.form.homepage.value,
          url1:
            this.form.subPromotionPageList.item[0].value === "" ||
            this.form.subPromotionPageList.item[0].value === null
              ? ""
              : this.form.subPromotionPageList.item[0].value,
          urlNm1: "기타",
          url2:
            this.form.subPromotionPageList.item[1].value === "" ||
            this.form.subPromotionPageList.item[1].value === null
              ? ""
              : this.form.subPromotionPageList.item[1].value,
          urlNm2: "기타",
          url3:
            this.form.subPromotionPageList.item[2].value === "" ||
            this.form.subPromotionPageList.item[2].value === null
              ? ""
              : this.form.subPromotionPageList.item[2].value,
          urlNm3: "기타",
          rsvUrl:
            this.form.resvPage.value === "" || this.form.resvPage.value === null
              ? null
              : this.form.resvPage.value,
          mapAttchId: this.GET_PROMOTION.mapAttchId,
          logoAttchId: this.GET_PROMOTION.logoAttchId,
          picAttchId: this.GET_PROMOTION.picAttchId,
          drPicAttchId: this.GET_PROMOTION.drPicAttchId,
          prAttchId: this.GET_PROMOTION.prAttchId,
          mapType:
            this.form.attachment[3].source !== null
              ? "U"
              : this.GET_PROMOTION.mapType,
        };
        if (this.view.isEditorMenu) {
          if (this.GET_PROMOTION.docAddvTitle !== null) {
            prParam.docAddvTitle = this.form.editor.title;
            prParam.docAddvConts = this.form.editor.content;
          } else {
            if (this.form.editor.title !== "") {
              prParam.docAddvTitle = this.form.editor.title;
              prParam.docAddvConts = this.form.editor.content;
            }
          }
        }
        if (
          prParam.hospIntroType === 3 &&
          this.form.attachment[0].url === null
        ) {
          // console.log(this.form.attachment[0]);
          prParam.hospIntroType = 4;
        }
        // console.log("pr파람데이터 :");
        // console.log(prParam);

        /** 벡엔드 스트링으로 파싱하기 때문에 직렬화를해준다. */
        const transedPrParam = JSON.stringify(prParam);
        const transData = new FormData();
        const transData1 = new FormData();
        transData.append("prParam", transedPrParam);
        if (type) {
          transData.append(
            "drPicFile",
            this.form.attachment[0].source === null
              ? this.GET_PROMOTION.drPicAttchId
              : this.form.attachment[0].source
          );
          transData.append(
            "logoFile",
            this.form.attachment[1].source === null
              ? this.GET_PROMOTION.logoAttchId
              : this.form.attachment[1].source
          );
          /////////////////////////////

          if (this.selectItems === "A형") {
            transData.append(
              "picFile",
              this.form.attachment[2].source === null
                ? this.GET_PROMOTION.picAttchId
                : this.form.attachment[2].source
            );
          } else {
            // console.log(this.multiFile);
            if (this.multiFile !== "" && this.multiFile !== null) {
              for (var i = 0; i < this.multiFile.length; i++) {
                // console.log(this.multiFile[i]);
                transData1.append("picFile", this.multiFile[i]);
              }
            }
          }
          transData.append(
            "prFile",
            this.form.attachment[4].source === null
              ? this.GET_PROMOTION.picAttchId
              : this.form.attachment[4].source
          );
        } /*else {
          if (this.form.attachment[0].source !== null) {
            transData.append("drPicFile", this.form.attachment[0].source);
          }
          if (this.form.attachment[1].source !== null) {
            transData.append("logoFile", this.form.attachment[1].source);
          }
          //
          if (this.form.attachment[2].length === undefined) {
            if (this.form.attachment[2].source !== null) {
              transData.append("picFile", this.form.attachment[2].source);
            }
          } else {
            if (
              this.form.attachment[2] !== "" &&
              this.form.attachment[2] !== null
            ) {
              console.log("brfore count : ");
              console.log(this.form.attachment[2]);
              for (var i = 0; i < this.form.attachment[2].length; i++) {
                transData1.append("picFile", this.form.attachment[2][i]);
              }
            }
          }
          //

          if (this.form.attachment[4].source !== null) {
            transData.append("prFile", this.form.attachment[4].source);
          }
        }*/
        transData.append(
          "mapFile",
          this.form.attachment[3].source === null
            ? this.GET_PROMOTION.mapAttchId
            : this.form.attachment[3].source
        );
        // console.log("전송할 데이터(전체) :", transData);
        await this.PROMOTION_MODIFY(transData).then(async () => {
          const applyParam = {
            drId: localStorage.getItem("drId"),
            aplyConts: "",
            doctIntro2: this.form.introduceDoctor2.value,
            doctIntro3: this.form.introduceDoctor3.value,
            doctIntro4: this.form.introduceDoctor4.value,
            doctIntro5: this.form.introduceDoctor5.value,
            hospEngNm: this.form.hospNmEn,
            jinConts: this.form.jinConts,
            jinItems: this.form.jinItems,
            prodCd: localStorage.getItem("prodCd"),
          };
          const transedApplyParam = JSON.stringify(applyParam);

          transData1.append("applyParam", transedApplyParam);

          /*for (var pair of transData1.entries()) {
            console.log(pair[0]);
            console.log("/");
            console.log(pair[1]);
          }*/

          await this.SAVE_APPLY(transData1).then((data) => {
            if(data > 900){
              this.SET_POPUP(true);
              this.popupSet.title = "소개정보 등록실패";
              this.popupSet.content = " 소개정보를 등록할 수 없습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            this.SET_POPUP(true);
            this.popupSet.title = "신청서 작성 및 소개정보 등록성공";
            this.popupSet.content =
              `신청처 작성이 완료 되었습니다. \n <b class="point">https://www.drrefer.net/dr/` +
              localStorage.getItem("drId") +
              "</b> 에서 \n 선택하신 내용의 변경사항을 확인하실 수 있습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          });
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "소개정보 등록실패";
          this.popupSet.content = " 소개정보를 등록할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    loadPr(order) {
      window.scrollTo(0, 0);
      // console.log("순번Pr", order);
      this.$refs.tabs.selectTab(0);
      this.view.pageOrder = order;
      /**
      this.$nextTick(_ => {
        if (order === 0) {
          document.querySelector(".one .pr-info").classList.add("active");
        } else if (order === 1) {
          document.querySelector(".two .pr-info").classList.add("active");
        } else if (order === 2) {
          document.querySelector(".three .pr-info").classList.add("active");
        }
      });
       */
      this.$nextTick((_) => {
        if (order !== 1) {
          this.prePareTextarea();
        } else {
          this.prePareTextareaTypeB();
        }
      });
      this.$refs.float.markItem(this.view.pageOrder);
    },
    loadRef(order, sampleNum) {
      // console.log("순번 Ref", order);
      if (order === 0) {
        this.$refs.tabs.selectTab(1);
        this.view.isFooterVisible = true;
        this.view.pageOrder = null;
        this.$nextTick((_) => {
          this.prePareTextarea();
        });
      } else if (order === 1) {
        this.$refs.tabs.selectTab(0);
        this.view.pageOrder = sampleNum;
      }
      this.$nextTick((_) => {
        document.querySelector(".pr-info").classList.add("active");
      });
      this.$refs.float.markRef(order);
    },
  },
  destroyed() {
    this.setCount = false;
    this.SET_PASSED_URL(null);
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/pr";
@import "@/assets/css/join";

.sub-wrap {
  padding: 85px 0 105px 0;
  margin: 0 auto;
  position: relative;
  display: flex;
  max-width: 1240px;
  .side-menu {
  }
  .write-context {
    width: calc(100% - 217px);
    padding: 0 0 0 65px;
    margin: 0;
    .document {
      .j-article {
        .title-block {
          width: 205px;
          h5.title {
            font-size: 18px;
          }
        }
        .input-flex {
          .input-wrap {
            width: 100%;
          }
        }
        .attachment-wrap,
        .input-wrap {
          width: calc(100% - 235px);
        }
        .attachment-wrap .attachment {
          .a-input-wrap {
            width: calc(100% - 165px);
            margin: 0 25px 0 0;
            span.file-name {
              display: block;
              width: 100%;
              max-width: 445px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .a-btn-wrap {
          }
        }
      }
    }
  }
}
.write-context {
  max-width: 1140px;
  margin: 65px auto;
  .document {
    .j-article {
      .title-block {
        width: 260px;
      }
      .attachment-wrap,
      .input-wrap {
        width: calc(100% - 290px);
        textarea {
          overflow-y: scroll !important;
        }
      }
    }
  }
}

input, textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>

<style lang="scss">
.app-context {
  .pr-wrap {
    margin: 52.5px auto 0;
  }
}
.pr-context,
.pre-editor {
  .select-wrap {
    width: 110px;
    margin: 0 15px 0 0;
    position: relative;
    .v-select {
      height: 29px;
      .vs__selected-options {
        span {
          font-size: 15px;
          line-height: 27px;
          font-family: "AppleSDGothicNeoR00";
          display: block;
          padding: 0 6px 0 4px;
        }
      }
    }
  }
}
</style>
